import React from "react";
import styled from "styled-components";
import { ImageLeftWrapper } from "../../../components/images/images";
import { Headline3, Paragraph } from "../../../components/typography";
import WorkingTogetherSection from "../../../components/work/working-together-section";
import { theme } from "../../../styles/styles";
import QuoteElement from "../../../components/quote-element/quote-element";
import { getElementImage, getElementText } from "../../../utilities/pimcore-data-transformer";
import { PimcoreImage } from "../../../components/pimcore-image";
import { PageElement } from "../../../graphql/page-common";
import { useMatchMedia } from "../../../hooks/use-match-media";
import { onScrollAnimationDesktop } from "../../../utilities/animation-helper";
import WorkTextIntro from "../work-text-intro";

interface WorkingSectionProps {
  headline: string;
  intro: Array<PageElement>;
  content: Array<PageElement>;
}

interface ParagraphProps {
  padding?: string;
}

const WorkingSection: React.FC<WorkingSectionProps> = ({ headline, intro, content }) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(wrapper.current, [wrapper.current?.children || null], matchMobile);

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <>
      <WorkTextIntro content={getElementText(intro[1])} />
      <SectionWrapper>
        <WorkingTogetherSection
          headline={headline}
          subHeadline={getElementText(content[0])}
          image={getElementImage(content[1])}
          content={getElementText(content[2])}
          quote={{
            message:
              "We aim to deliver an eCommerce platform that can provide scalability and short time to market, for a premium, frictionless shopping experience.",
            name: "Jyoti",
            position: "Frontend Engineer",
            modifier: "left-4 bottom-4 tablet:left-[32rem] tablet:bottom-80",
            left: true,
          }}
          marginBottom="tablet:mb-0"
          padding="tablet:pr-16"
        />
        <Grid ref={wrapper}>
          <MediaCell1>
            <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[3]) }} />
          </MediaCell1>
          <MediaCell2>
            <ImageLeftWrapper>
              <PimcoreImage image={getElementImage(content[4])} modifier="w-full rounded-lg" withAspectRatio />
            </ImageLeftWrapper>
          </MediaCell2>
          <MediaCell3>
            <ParagraphWrapper>
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[5]) }} />
            </ParagraphWrapper>
          </MediaCell3>
          <MediaCell4>
            <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[6]) }} />
          </MediaCell4>
          <MediaCell5>
            <QuoteElement
              message="Premium products require a premium experience. By building robust core software, we enable our fellow engineers to craft these digital experiences consistently – and effectively."
              name="Anton"
              position="Frontend Engineer"
              modifier="left-4 bottom-4 tablet:bottom-80 tablet:left-[32rem]"
              left={true}
            />
            <PimcoreImage image={getElementImage(content[7])} modifier="w-full rounded-lg" withAspectRatio />
          </MediaCell5>
          <MediaCell6>
            <ParagraphWrapper>
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[8]) }} />
            </ParagraphWrapper>
          </MediaCell6>
          <MediaCell7>
            <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[9]) }} />
          </MediaCell7>
          <MediaCell8>
            <ImageLeftWrapper>
              <PimcoreImage image={getElementImage(content[10])} modifier="w-full rounded-lg" withAspectRatio />
            </ImageLeftWrapper>
          </MediaCell8>
          <MediaCell9>
            <ParagraphWrapper padding="tablet:pr-12">
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[11]) }} />
            </ParagraphWrapper>
          </MediaCell9>
          <MediaCell10>
            <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[12]) }} />
          </MediaCell10>
          <MediaCell11>
            <PimcoreImage image={getElementImage(content[13])} modifier="w-full rounded-lg" withAspectRatio />
          </MediaCell11>
          <MediaCell12>
            <ParagraphWrapper padding="tablet:pr-10">
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[14]) }} />
            </ParagraphWrapper>
          </MediaCell12>
          <MediaCell13>
            <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[15]) }} />
          </MediaCell13>
          <MediaCell14>
            <ImageLeftWrapper>
              <PimcoreImage image={getElementImage(content[16])} modifier="w-full rounded-lg" withAspectRatio />
            </ImageLeftWrapper>
          </MediaCell14>
          <MediaCell15>
            <ParagraphWrapper>
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[17]) }} />
            </ParagraphWrapper>
          </MediaCell15>
        </Grid>
      </SectionWrapper>
    </>
  );
};

export default WorkingSection;

const SectionWrapper = styled.div.attrs({ className: "scrollspy" })``;

const Grid = styled.div.attrs({
  className: "tablet:grid tablet:grid-cols-12 gap-4 px-5",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(18, auto);
  }
`;
const ParagraphWrapper = styled.div.attrs<ParagraphProps>(({ padding }) => ({
  className: `${padding ? padding : "tablet:pr-14"}`,
})) <ParagraphProps>``;
const MediaCell1 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-4 tablet:row-span-1 tablet:mt-20",
})``;
const MediaCell2 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-6 tablet:row-start-4 tablet:row-span-3 mb-8 tablet:mb-36",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-5 tablet:row-span-1 mb-14 tablet:mb-0",
})``;
const MediaCell4 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-3 tablet:row-start-7 tablet:row-span-1 tablet:mt-20",
})``;
const MediaCell5 = styled.div.attrs({
  className: "tablet:col-start-6 tablet:col-span-6 tablet:row-start-7 tablet:row-span-3 mb-8 tablet:mb-36 relative",
})``;
const MediaCell6 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-8 tablet:row-span-1 mb-14 tablet:mb-0",
})``;
const MediaCell7 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-3 tablet:row-start-10 tablet:row-span-1",
})``;
const MediaCell8 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-6 tablet:row-start-10 tablet:row-span-3 mb-8 tablet:mb-36",
})``;
const MediaCell9 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-11 tablet:row-span-1 mb-14 tablet:mb-0",
})``;
const MediaCell10 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-3 tablet:row-start-13 tablet:row-span-1 tablet:mt-20",
})``;
const MediaCell11 = styled.div.attrs({
  className: "tablet:col-start-6 tablet:col-span-6 tablet:row-start-13 tablet:row-span-3 mb-8 tablet:mb-36",
})``;
const MediaCell12 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-14 tablet:row-span-1 mb-14 tablet:mb-0",
})``;
const MediaCell13 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-3 tablet:row-start-16 tablet:row-span-1 tablet:mt-20",
})``;
const MediaCell14 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-6 tablet:row-start-16 tablet:row-span-3 mb-8 tablet:mb-36",
})``;
const MediaCell15 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-17 tablet:row-span-1 mb-14 tablet:mb-0",
})``;
