import React from "react";
import styled from "styled-components";
import HeaderImage from "../../../components/header-image/header-image";
import { BenefitsTile, TileNavigation } from "../../../components/tile-navigation";
import { CategoryJobOffers } from "../../../components/job-offer";
import WorkingSection from "../../../sections/work/it/working-section";
import { JobOfferListingInterface } from "../../../graphql/jobs";
import { graphql, PageProps } from "gatsby";
import Layout from "../../../components/layout";
import { WorkCategoryPageContentInterface } from "../../../graphql/work-category-page";
import MeetTeam from "../../../components/work/meet-team";
import { Routes } from "../../../data/routing";
import { BlogPostListingInterface } from "../../../graphql/blog";
import RelatedBlogPosts from "../../../sections/work/related-blog-posts";
import { useScrollSpyLabels } from "../../../hooks/use-scrollspy-labels";
import { ScrollSpyContextProvider } from "../../../components/scrollspy/scrollspy-context";

interface WorkItPagePageDataInterface {
  pimcore: {
    content: WorkCategoryPageContentInterface;
    categoryJobOffers: JobOfferListingInterface;
    blogPosts: BlogPostListingInterface;
  };
}

const labels = ["Our work", "Working Together", "Meet some of the team"];

const WorkItPage: React.FC<PageProps<WorkItPagePageDataInterface>> = ({ data }) => {
  const content = data.pimcore.content;
  const scrollspyLabels = useScrollSpyLabels(labels, data.pimcore.blogPosts.totalCount, data.pimcore.categoryJobOffers.totalCount);

  return (
    <Layout
      navCurrentItem="work"
      navCurrentSubItem="it"
      title={content.metaTitle}
      description={content.metaDescription}
      canonical={content.metaCanonical}
      openGraph={{ title: content.metaOgTitle, description: content.metaOgTitle, image: content.metaOgImage }}
      schemaOrg={content.metaSchema}
    >
      <Header>
        <HeaderImage
          headline={content.heroHeadline}
          subHeadline={content.heroSubheadline}
          image={content.heroBackground}
          mobileImage={content.heroBackgroundMobile}
          btnSrc={Routes.work}
          btnLabel="Back to Work overview"
        />
      </Header>
      <ScrollSpyContextProvider
        labels={scrollspyLabels}
        gap={(data.pimcore.categoryJobOffers.totalCount > 0 || data.pimcore.blogPosts.totalCount > 0) ? 7 : 1}
        margin={(data.pimcore.categoryJobOffers.totalCount > 0 || data.pimcore.blogPosts.totalCount > 0) ? 6 : 1}
      >
        <Main>
          <WorkingSection headline={content.workHeadline} intro={content.intro} content={content.work} />
          <MeetTeam headline={content.teamHeadline} members={content.team} />
          {data.pimcore.blogPosts.totalCount > 0 && (
            <RelatedBlogPosts
              blogPosts={data.pimcore.blogPosts}
              headline={content.blogPostsHeadline}
              description={content.blogPostsDescription}
            />
          )}
          {data.pimcore.categoryJobOffers.totalCount > 0 && (
            <CategoryJobOffers
              headline={content.jobsHeadline}
              description={content.jobsDescription}
              jobOffers={data.pimcore.categoryJobOffers.edges.map((edge) => edge.node)}
            />
          )}
          <TileNavigation top={"team"} bottom={"office"}>
            <BenefitsTile />
          </TileNavigation>
        </Main>
      </ScrollSpyContextProvider>
    </Layout>
  );
};

const Header = styled.header.attrs({ className: "h-screen" })``;
const Main = styled.div.attrs({ className: "x-background" })``;

export const query = graphql`
  query($fullpath: String = "/Pages/Home/Work/IT", $filter: String = "{\"category\":\"it\"}", $blogPostsFilter: String = "{\"departmentTags\": {\"$like\": \"%information-technology%\"}}") {
    pimcore {
      ...workCategoryPageData
      ...categoryJobOffers
      ...blogPostsFiltered
    }
  }
`;

export default WorkItPage;
